import React from "react";
import LandingNavBar from "./LandingNavBar";
import LandingNavBarMobile from "./LandingNavBarMobile";
import useBreakpoints from "../Context/useBreakPoints";

export default function Privacy() {
    const { isSm } = useBreakpoints();

    return (
        <div>
            {isSm ? <LandingNavBar /> : <LandingNavBarMobile />}
            <div style={styles.container}>
                <h2 style={styles.header}>Contact Information</h2>
                <div style={styles.info}>
                    <p>
                        <strong>Email:</strong>{" "}
                        <a href="mailto:slv.durga.temple@gmail.com" style={styles.link}>
                            slv.durga.temple@gmail.com
                        </a>
                    </p>
                    <p>
                        <strong>Phone:</strong>{" "}
                        <a href="tel:+917204213913" style={styles.link}>
                            +91 72042 13913
                        </a>
                    </p>
                </div>
                <h3 style={styles.subHeader}>Privacy Policy</h3>
            </div>
        </div>
    );
}

const styles = {
    container: {
        padding: "20px",
        maxWidth: "600px",
        margin: "0 auto",
        marginTop: '10px',
        fontFamily: "'Arial', sans-serif",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
    },
    header: {
        fontSize: "24px",
        marginBottom: "10px",
        color: "#333",
        textAlign: "center"
    },
    info: {
        fontSize: "18px",
        marginBottom: "20px",
        color: "#555",
    },
    link: {
        color: "#007BFF",
        textDecoration: "none"
    },
    subHeader: {
        fontSize: "20px",
        color: "#333",
        textAlign: "center",
        borderTop: "1px solid #ddd",
        paddingTop: "10px",
        marginTop: "20px"
    }
};
